/* global THREE */
export default (colors, canvasWidth, canvasHeight) => {
  const camera = new THREE.OrthographicCamera(
    canvasWidth / -2,
    canvasWidth / 2,
    canvasHeight / 2,
    canvasHeight / -2,
    1,
    4000
  );
  camera.position.z = 1600;

  const scene = new THREE.Scene();

  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    preserveDrawingBuffer: true
  });
  renderer.setSize(canvasWidth, canvasHeight);
  renderer.setRenderTarget();
  const texture_size = 1600;

  const cameraRTT = new THREE.OrthographicCamera(
    texture_size / -2,
    texture_size / 2,
    texture_size / 2,
    texture_size / -2,
    -10000,
    10000
  );
  cameraRTT.position.z = 400;

  const sceneRTT = new THREE.Scene();

  const rtTexture = new THREE.WebGLRenderTarget(texture_size, texture_size, {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBFormat
  });

  const matRTT = new THREE.ShaderMaterial({
    uniforms: {
      u_time: { value: 0.0 },
      u_resolution: {
        value: new THREE.Vector2(texture_size, texture_size)
      },
      u_color_0: {
        value: new THREE.Color(colors[0])
      },
      u_color_1: {
        value: new THREE.Color(colors[1])
      },
      u_color_2: {
        value: new THREE.Color(colors[2])
      },
      u_color_3: {
        value: new THREE.Color(colors[3])
      }
    },
    vertexShader: `varying vec2 vUv;
      void main() {
      	vUv = uv;
      	gl_Position = projectionMatrix * modelViewMatrix * vec4( (position * 1.0), 1.0 );
      }`,
    fragmentShader: `varying vec2 vUv;

      void main() {
      	vec2 uv = vUv;
      	gl_FragColor = vec4( 1.0 );
      }`
  });

  fetch("/shader.glsl")
    .then(res => {
      return res.text();
    })
    .then(text => {
      matRTT.fragmentShader = text;
      matRTT.needsUpdate = true;
    })
    .catch(err => {
      console.error(err);
    });

  const quadRTT = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(texture_size, texture_size),
    matRTT
  );
  sceneRTT.add(quadRTT);

  return { renderer, rtTexture, scene, camera, matRTT, sceneRTT, cameraRTT };
};
