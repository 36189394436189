/* global THREE */
export default (rtTexture, canvasWidth, canvasHeight) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      tDiffuse: { value: rtTexture.texture },
      u_time: { value: 0.0 }
    },
    vertexShader: `varying vec2 vUv;

      void main() {
      	vUv = uv;
      	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }`,
    fragmentShader: `precision highp float;
      uniform sampler2D tDiffuse;
      uniform float u_time;
      varying vec2 vUv;
      const float speed = 0.5;
      const float magnitude = 0.15;

      const float radius = ${(canvasHeight * 2).toFixed(1)};
      const float angle = 0.5;
      const vec2 center = vec2(${(canvasWidth / 2).toFixed(1)}, ${(
      canvasHeight / 2
    ).toFixed(1)});

      vec4 PostFX(sampler2D tex, vec2 uv, float time)
      {
        vec2 texSize = vec2(${canvasWidth.toFixed(1)}, ${canvasHeight.toFixed(
      1
    )});
        vec2 tc = uv * texSize;
        tc -= center;
        float dist = length(tc);
        if (dist < radius)
        {
          float percent = (radius - dist) / radius;
          float theta = percent * percent * angle * 4.0 * cos(u_time+uv.s);
          float s = sin(theta);
          float c = cos(theta);
          s = s + (sin(u_time+uv.t*speed) * magnitude);
          c = c + (cos(u_time+uv.s*speed) * magnitude);
          tc = vec2(dot(tc, vec2(c, -s)), dot(tc, vec2(s, c)));
        }
        tc += center;
      	vec2 wavyCoord;
        vec3 color = texture2D(tex, tc / texSize).rgb;
        return vec4(color, 1.0);
      }

      void main() {
      	vec4 frameColor = PostFX(tDiffuse, vUv, u_time);
      	gl_FragColor = frameColor;
      }`,

    depthWrite: false,
    side: THREE.DoubleSide
  });
};
