/* global THREE */

class Influencer {
  setup(x, y, z, radius) {
    this.anchor = new THREE.Vector3(x, y, z);
    this.effectradius = radius;
    this.map = new Map();
    this.geometry = null;
  }

  surround = (geometry, width, length) => {
    this.geometry = geometry;

    var distance;
    for (var i = 0; i < geometry.vertices.length; i++) {
      if (
        i % (width + 1) === 0 ||
        i % (width + 1) === width ||
        i < width ||
        i > geometry.vertices.length - (width + 1)
      ) {
        continue;
      }

      distance = geometry.vertices[i].distanceTo(this.anchor);

      if (distance < this.effectradius) {
        var factor = distance / this.effectradius;
        var weight =
          ((1 / (Math.sqrt(2 * 3.14) * 0.25)) *
            Math.exp((-factor * factor) / (2 * 0.25 * 0.25))) /
          1.6;

        this.map.set(i, weight);
      }
    }
  };

  moveTo = (target_x, target_y, target_z) => {
    var geometry = this.geometry;

    if (
      this.anchor.x === target_x &&
      this.anchor.y === target_y &&
      this.anchor.z === target_z
    ) {
      return;
    }

    var dx = target_x - this.anchor.x;
    var dy = target_y - this.anchor.y;
    var dz = target_z - this.anchor.z;

    this.map.forEach(function(value, index, mapObj) {
      geometry.vertices[index].x += dx * value;
      geometry.vertices[index].y += dy * value;
      geometry.vertices[index].z += dz * value;
    });
    this.anchor.set(target_x, target_y, target_z);
    return geometry;
  };
}

export default Influencer;
