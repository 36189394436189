/* global THREE */
import React, { useRef, useEffect } from "react";
import Influencer from "./Influencer";
import setupRenderer from "./renderer/setup";
import buildResources from "./renderer/resources";
import { getRandomInt } from "./utils";
import AWS from "aws-sdk";
import "./App.css";

const throttle = (func, limit) => {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const spacesEndpoint = new AWS.Endpoint("ams3.digitaloceanspaces.com");
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "F7BCCQJWSZQMO4HVZBBI",
  secretAccessKey: "GmvtQhOd0ApKOXkKYTye7Er7v7QZDYplyZBWq2+I2WI"
});

const radius = 900;
const influencer = new Influencer();
const startColors = ["#FF0000", "#FFFF00", "#FF00FF", "#00AEFF"];
let frame = getRandomInt(0, 900);
let scene = undefined;
let quadGeo = undefined;
let quadMat = undefined;
let quad = undefined;
let mat = undefined;
let canvasHeight = 0;
let canvasWidth = 0;
let startDrag = undefined;
let startVertecies = undefined;
let isDragging = false;
let animate = () => {
  console.log("Called before load");
};
let globalPattern = [];
let history = [];
let currentPoint = undefined;

const moveVertices = (points, redraw = false) => {
  const p = points || globalPattern;
  /*if (redraw) {
    for (var i = 0; i < quadGeo.vertices.length; i++) {
      quadGeo.vertices[i].x = startVertecies[i].x;
      quadGeo.vertices[i].y = startVertecies[i].y;
      quadGeo.vertices[i].z = startVertecies[i].z;
    }
  }
  p.forEach(point => {
    influencer.setup(
      point.anchorX,
      point.anchorY,
      point.anchorZ,
      point.anchorRadius
    );
    influencer.surround(
      quadGeo,
      Math.floor(canvasWidth / 12),
      Math.floor(canvasHeight / 12)
    );
    influencer.moveTo(
      point.moveToX,
      point.moveToY,
      point.moveToZ || globalPattern.length * 10
    );
  });*/

  const point = globalPattern[globalPattern.length - 1];
  if (!isDragging) {
    influencer.setup(
      point.anchorX,
      point.anchorY,
      point.anchorZ,
      point.anchorRadius
    );
    influencer.surround(
      quadGeo,
      Math.floor(canvasWidth / 12),
      Math.floor(canvasHeight / 12)
    );
  }
  influencer.moveTo(
    point.moveToX,
    point.moveToY,
    point.moveToZ || globalPattern.length * 10
  );

  animate();
};

window.restart = () => {
  for (let i = 0; i < quadGeo.vertices.length; i++) {
    quadGeo.vertices[i].x = startVertecies[i].x;
    quadGeo.vertices[i].y = startVertecies[i].y;
    quadGeo.vertices[i].z = startVertecies[i].z;
  }
  animate();
};

window.stepBack = () => {
  if (globalPattern.length > 0) {
    history = history.slice(0, -1);
    if (history.length > 0) {
      const vert = history[history.length - 1];
      for (let i = 0; i < quadGeo.vertices.length; i++) {
        quadGeo.vertices[i].x = vert[i].x;
        quadGeo.vertices[i].y = vert[i].y;
        quadGeo.vertices[i].z = vert[i].z;
      }
    } else {
      for (let i = 0; i < quadGeo.vertices.length; i++) {
        quadGeo.vertices[i].x = startVertecies[i].x;
        quadGeo.vertices[i].y = startVertecies[i].y;
        quadGeo.vertices[i].z = startVertecies[i].z;
      }
    }
  }
  animate();
};

window.startPattern = () => {
  globalPattern = [
    {
      anchorX: 240,
      anchorY: -200,
      anchorZ: 0,
      anchorRadius: 900,
      moveToX: 260,
      moveToY: 160,
      moveToZ: 400
    },
    {
      anchorX: -240,
      anchorY: 200,
      anchorZ: 0,
      anchorRadius: 900,
      moveToX: -260,
      moveToY: -160,
      moveToZ: 400
    }
  ];
  moveVertices();
};

window.download = (saveImage = false) => {
  const canvas = document.querySelector("canvas");
  const imgData = canvas.toDataURL({ format: "png" });
  if (window.webkit) {
    window.webkit.messageHandlers.download.postMessage(imgData.slice(22));
  }
  const buf = new Buffer(
    imgData.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
  if (saveImage) {
    const params = {
      Body: buf,
      Bucket: "hqwp",
      Key: new Date().getTime() + ".png",
      ACL: "public-read"
    };
    s3.putObject(params, function(err, data) {
      if (err) console.log(err, err.stack);
      else console.log(data);
    });
  }
};

window.changeColor = (index, color) => {
  if (!color) {
    console.log("no color");
    return;
  }
  switch (parseInt(index)) {
    case 0:
      mat.uniforms.u_color_0.value.set(color);
      break;
    case 1:
      mat.uniforms.u_color_1.value.set(color);
      break;
    case 2:
      mat.uniforms.u_color_2.value.set(color);
      break;
    case 3:
      mat.uniforms.u_color_3.value.set(color);
      break;
    default:
      break;
  }
  mat.needsUpdate = true;
  console.log(color);
  animate();
};

window.rotateColors = f => {
  frame = parseInt(f);
  animate();
};

export default () => {
  const containerRef = useRef();

  useEffect(() => {
    if (window.webkit) {
      window.webkit.messageHandlers.react.postMessage("loaded");
    }
  }, []);

  const setupFromApp = (width, height) => {
    if (window.webkit) {
      window.webkit.messageHandlers.logger.postMessage(
        `${parseInt(width)}, ${parseInt(height)}`
      );
    }
    canvasHeight = parseInt(height);
    canvasWidth = parseInt(width);
    quadGeo = new THREE.PlaneGeometry(
      parseInt(width),
      parseInt(height),
      Math.floor(parseInt(width) / 12),
      Math.floor(parseInt(height) / 12)
    );
    const {
      renderer,
      rtTexture,
      scene: s,
      camera,
      matRTT,
      sceneRTT,
      cameraRTT
    } = setupRenderer(startColors, canvasWidth, canvasHeight);
    scene = s;
    mat = matRTT;
    containerRef.current.appendChild(renderer.domElement);
    quadMat = buildResources(rtTexture, canvasWidth, canvasHeight);
    quad = new THREE.Mesh(quadGeo, quadMat);
    startVertecies = quadGeo.vertices.map(v => {
      return {
        x: v.x,
        y: v.y,
        z: v.z
      };
    });
    scene.add(quad);
    animate = () => {
      mat.uniforms.u_time.value = frame / 50;
      quadMat.uniforms.u_time.value = frame / 50;
      quadMat.needsUpdate = true;
      quadGeo.verticesNeedUpdate = true;
      renderer.clear();
      renderer.setRenderTarget(rtTexture);
      renderer.render(sceneRTT, cameraRTT);
      renderer.setRenderTarget(null);
      renderer.render(scene, camera);
    };
    animate();
    setTimeout(() => {
      animate();
    }, 1000);
  };
  window.setup = setupFromApp;

  const dragStart = e => {
    if (!startDrag) {
      startDrag = {
        x: e.targetTouches[0].clientX,
        y: e.targetTouches[0].clientY
      };
    }
  };

  const dragMove = e => {
    if (!startDrag) {
      return;
    }
    const endDrag = {
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY
    };

    const boundingRect = containerRef.current.getBoundingClientRect();
    const xMultipler = canvasWidth / boundingRect.width;
    const yMultipler = canvasHeight / boundingRect.height;
    const point = {
      anchorX:
        (startDrag.x - boundingRect.left - boundingRect.width / 2) * xMultipler,
      anchorY:
        (startDrag.y - boundingRect.top - boundingRect.height / 2) *
        yMultipler *
        -1,
      anchorZ: 0,
      anchorRadius: radius,
      moveToX:
        (endDrag.x - boundingRect.left - boundingRect.width / 2) * xMultipler,
      moveToY:
        (endDrag.y - boundingRect.top - boundingRect.height / 2) *
        yMultipler *
        -1
    };
    //currentPoint = point;
    //moveVertices([...globalPattern, point], true);
    const sliced = globalPattern.slice(0, -1);
    globalPattern = [...sliced, point];
    moveVertices();
    startDrag = endDrag;
    isDragging = true;
  };

  const dragMoveThrottled = throttle(dragMove, 16);

  const dragEnd = e => {
    /*if (!currentPoint) {
      console.log("no currentpoint");
      return;
    }
    globalPattern = [...globalPattern, currentPoint];
    moveVertices(globalPattern, true);*/
    //currentPoint = undefined;
    startDrag = undefined;
    isDragging = false;

    const h = quadGeo.vertices.map(v => {
      return { x: v.x, y: v.y, z: v.z };
    });
    history = [...history, h];
  };

  return (
    <div id="mobile-view" unselectable="on">
      <div
        id="mobile-container"
        ref={containerRef}
        onTouchStart={dragStart}
        onTouchMove={dragMove}
        onTouchEnd={dragEnd}
        onTouchCancel={dragEnd}
      />
    </div>
  );
};
