/* global THREE */

export const randomHexColor = () => {
  return '#000000'.replace(/0/g, () => {
    return (~~(Math.random() * 16)).toString(16)
  })
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const dataURLtoBlob = imgData => {
  const arr = imgData.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const tweenColor = (cc, tc, frame, maxFrames) => {
  var currentColor = new THREE.Color(cc)
  const tweenColor = new THREE.Color(tc)

  const redInterval = (tweenColor.r - currentColor.r) / maxFrames
  const greenInterval = (tweenColor.g - currentColor.g) / maxFrames
  const blueInterval = (tweenColor.b - currentColor.b) / maxFrames
  currentColor.r += redInterval * frame
  currentColor.g += greenInterval * frame
  currentColor.b += blueInterval * frame
  return currentColor
}

export const CONSTANTS = {
  CANVAS_WIDTH: 1242,
  CANVAS_HEIGHT: 2688
}
